import protologo from "./images/protocodersTransparentWhite.png";
import "./css/Core.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        
        <img src={protologo} className="App-logo" alt="logo" />
        <p>Protocoders is a software development company located in Timisoara, Romania.</p>
        <p>We offer unique full-stack software development services and products.</p>
        <p>If you are interested in working with us or for any other information feel free to contact us at office@protocoders.com</p>
        
      </header>
    </div>
  );
}

export default App;
